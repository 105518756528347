/* globals jQuery */

/**
 * contact form
 */
(function ($, document, window) {
    'use strict';
    $(document).ready(function () {
        /**
         * display message in a modal
         * @param message
         */
        function popMessageDisplay(message) {
            $('<div id="pop" class="pop"></div>')
                .append(message)
                .appendTo('body')
                .fadeIn(500);

            setTimeout(() => {
                location.assign('/');
            }, 3000);
        }

        /**
         * Contact form manager
         */

        $('#sirenContact').inputmask('999 999 999');

        $('#contact-form').validate({
            rules: {
                name: {
                    required: true,
                    minlength: 2,
                },
                email: {
                    required: true,
                    email: true,
                },
                message: {
                    required: true,
                },
                siren: {
                    minlength: 11, // 3 for spaces
                    maxlength: 11,
                },
            },
            messages: {
                name: {
                    required: 'Veuillez renseigner ce champ.',
                    minlength:
                        'Ce champ doit comporter au moins deux caractères.',
                },
                email: {
                    required: 'Veuillez renseigner ce champ.',
                    email: "Cet email n'est pas valide.",
                },
                message: {
                    required: 'Veuillez renseigner ce champ.',
                },
                siren: {
                    digits: 'Format du SIREN invalide.',
                    minlength: 'Format du SIREN invalide.',
                    maxlength: 'Format du SIREN invalide.',
                },
            },
            errorPlacement: (error, element) => {
                $(element)
                    .parent('div.input-group, div.textarea')
                    .addClass('error')
                    .append(error);
            },
            success: (label, element) => {
                $(element)
                    .next('label.error')
                    .remove()
                    .end()
                    .parent('div.input-group, div.textarea')
                    .removeClass('error');
            },
            submitHandler: (form) => {
                $(form).find("button[type='submit']").prop('disabled', true);

                const responseCaptcha = $('#g-recaptcha-response')
                    ? $('#g-recaptcha-response').val()
                    : '';

                var name = $('#name').val();
                var email = $('#email').val();
                var message = $('#message').val();
                var siren = $('#sirenContact')
                    .val()
                    .replace(/[ _]/g, '')
                    .trim();
                $.post('/contact', {
                    name: name,
                    email: email,
                    message: message,
                    siren: siren,
                    captcha: responseCaptcha,
                })
                    .done(() => {
                        $('#name').val('');
                        $('#email').val('');
                        $('#message').val('');
                        $('#sirenContact').val('');
                        popMessageDisplay(
                            "Votre message a été envoyé.<br/> Vous allez recevoir un mail de confirmation de votre demande.<br> Vous allez être redirigé sur la page d'accueil"
                        );
                        $(form)
                            .find("button[type='submit']")
                            .prop('disabled', false);
                    })
                    .fail((error) => {
                        console.error('error contact', error);
                        alert(
                            "Un problème est survenu ! Votre message n'a pas été envoyé."
                        );
                    });
                return false;
            },
        });
    });
})(jQuery, document, window);
