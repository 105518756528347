/* globals jQuery */
/* globals Chart */

/**
 * Chart and webservice called in detail page
 */
(function ($, document, window) {
    'use strict';
    $(document).ready(function () {
        /**
         * Variables declarations
         */

        var chart, dataSet, canvasIdSelector;

        var $webservicesSections = $(
            'div.external-section, section.detail-result-key-figures'
        );

        /**
         * Load providers info in detail display
         */
        if ($webservicesSections.length) {
            /* init */
            $webservicesSections.each(function () {
                var $webserviceDiv = $(this),
                    provider = $webserviceDiv.data('provider'),
                    siren = location.pathname.match(/[0-9]{9}/g)
                        ? location.pathname.match(/[0-9]{9}/g)[0]
                        : null;

                $webserviceDiv
                    .find('div.external-zone')
                    .html(
                        '<div class="spinner"><img src="/img/spinner.gif" alt=""></div>'
                    );
                /* launch request */

                getWebServiceInfo(provider, siren, (data) => {
                    if (data) {
                        if (provider === 'dataInfogreffe') {
                            if (
                                data.chartData.status &&
                                data.chartData.status !== 'error'
                            ) {
                                dataSet = data.chartData.data;
                                canvasIdSelector = 'key-figures-graph';
                                var tableSelector =
                                    'div.key-figures-list table';

                                $webserviceDiv
                                    .find('div.external-zone')
                                    .html(data.html);

                                setDetailResultKeyFiguresManager(() => {
                                    setArrayDisplay(
                                        tableSelector,
                                        data.chartData
                                    );
                                });
                            } else {
                                $('section.detail-result-key-figures').remove();
                            }
                        } else {
                            $webserviceDiv
                                .find('div.external-zone')
                                .html(data.html);
                        }
                    }
                });
            });
        }

        /**
         * Generate and display dataInfogreffe's data in a chart.js's graph
         * @param canvasIdSelector
         * @param data
         */
        function setChartDisplay(canvasIdSelector, data) {
            if (!canvasIdSelector || !data) {
                return;
            }

            var canvas = document.getElementById(canvasIdSelector),
                labels = data.map((obj) => obj.year),
                dataArr = {
                    ca: data.map((obj) => parseInt(obj.ca, 10) || null),
                    results: data.map((obj) => obj.resultsFormatted),
                    effectif: data.map(
                        (obj) => parseInt(obj.effectif, 10) || null
                    ),
                    ratio: data.map(
                        (obj) => parseInt(obj.ratioCaResults, 10) || null
                    ),
                },
                dataArrFormatted = {
                    ca: data.map((obj) => obj.caFormatted),
                    results: data.map((obj) => obj.resultsFormatted),
                    effectif: data.map((obj) => obj.effectifFormatted),
                    ratio: data.map((obj) => obj.ratioCaResultsFormatted),
                },
                datasets = [],
                legendTooltip = {
                    display: false,
                    html:
                        '<div class="legend-tooltip">Cliquer pour supprimer/ajouter la courbe</div>',
                    duration: 1000,
                    setTimeoutID: null,
                };

            if (dataArr.ca.some((val) => !!val)) {
                datasets.push({
                    label: "CHIFFRE(S) D'AFFAIRES",
                    type: 'line',
                    fill: false,
                    lineTension: 0.1,
                    yAxisID: 'y1',
                    backgroundColor: '#F93165',
                    borderColor: '#F93165',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(232,86,109,1)',
                    pointBackgroundColor: 'rgba(232,86,109,1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(232,86,109,1)',
                    pointHoverBorderColor: 'rgba(232,86,109,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 3,
                    pointHitRadius: 10,
                    data: dataArr.ca,
                    dataFormatted: dataArrFormatted.ca,
                    spanGaps: false,
                    barPercentage: 0.5,
                });
            }
            if (dataArr.results.some((val) => !!val)) {
                datasets.push({
                    label: 'Résultat(s)'.toUpperCase(),
                    type: 'line',
                    fill: false,
                    lineTension: 0.1,
                    yAxisID: 'y2',
                    backgroundColor: 'rgba(74,94,110,1)',
                    borderColor: 'rgba(74,94,110,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(74,94,110,1)',
                    pointBackgroundColor: 'rgba(74,94,110,1)',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(74,94,110,1)',
                    pointHoverBorderColor: 'rgba(74,94,110,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 3,
                    pointHitRadius: 10,
                    data: dataArr.results,
                    dataFormatted: dataArrFormatted.results,
                    ratioData: dataArr.ratio,
                    spanGaps: false,
                    barPercentage: 0.5,
                });
            }

            if (dataArr.effectif.some((val) => !!val)) {
                datasets.push({
                    label: 'EFFECTIF(S)',
                    type: 'bar',
                    yAxisID: 'y3',
                    backgroundColor: 'rgba(179,242,255,1)',
                    borderColor: 'rgba(179,242,255,1)',
                    data: dataArr.effectif,
                    dataFormatted: dataArrFormatted.effectif,
                    barPercentage: 0.5,
                });
            } else {
                datasets.push({
                    type: 'bar',
                    label: '',
                    backgroundColor: 'rgba(179,242,255,0)',
                    borderColor: 'rgba(179,242,255,0)',
                    data: [],
                    barPercentage: 0.5,
                });
            }

            var chartData = { labels: labels, datasets: datasets },
                options = {
                    legend: {
                        labels: {
                            boxWidth: 10,
                            fontColor: '#849cae',
                            fontSize: 10,
                        },
                        onHover: (event, legendItem) => {
                            if (!$('#legend-tooltip').length) {
                                $(legendTooltip.html)
                                    .appendTo($('.detail-result-key-figures'))
                                    .attr('id', 'legend-tooltip');
                            }

                            if (!legendTooltip.display) {
                                $('#legend-tooltip').fadeIn();
                                legendTooltip.display = true;
                            }

                            if (legendTooltip.setTimeoutID) {
                                clearTimeout(legendTooltip.setTimeoutID);
                            }
                            legendTooltip.setTimeoutID = window.setTimeout(
                                () => {
                                    legendTooltip.display = false;
                                    $('#legend-tooltip').fadeOut();
                                },
                                legendTooltip.duration
                            );
                        },
                    },
                    tooltips: {
                        backgroundColor: '#e2e2e2',
                        bodyFontColor: '#4a5e6e',
                        cornerRadius: 3,
                        mode: 'label',
                        callbacks: {
                            label: (tooltipItems, data) => {
                                var formattedLabel =
                                    data.datasets[tooltipItems.datasetIndex]
                                        .dataFormatted[tooltipItems.index];
                                formattedLabel = formattedLabel
                                    ? ' ' + formattedLabel
                                    : formattedLabel;

                                if (tooltipItems.datasetIndex === 1) {
                                    var ratioLabel =
                                        data.datasets[tooltipItems.datasetIndex]
                                            .ratioData[tooltipItems.index];
                                    if (ratioLabel) {
                                        ratioLabel += '';
                                        formattedLabel =
                                            formattedLabel +
                                            ' (' +
                                            ratioLabel.replace('.', ',') +
                                            '%)';
                                    }
                                }
                                return tooltipItems.dataIndex === 2
                                    ? tooltipItems.yLabel
                                    : formattedLabel;
                            },
                        },
                    },
                    scales: {
                        yAxes: [
                            { id: 'y1', display: false, stacked: true },
                            {
                                id: 'y2',
                                ticks: {
                                    callback: (value) => (!value ? value : ''),
                                },
                                stacked: true,
                            },
                            { id: 'y3', display: false, stacked: true },
                        ],
                        xAxes: [
                            {
                                gridLines: {
                                    offsetGridLines: true,
                                    display: false,
                                },
                            },
                        ],
                    },
                    scaleBeginAtZero: false,
                };

            chart = window.chart = new Chart(canvas, {
                type: 'bar',
                data: chartData,
                options: options,
            });

            var axisY2 = chart.scales.y2;
            var axisY3 = chart.scales.y3;

            if (axisY2.min < 0) {
                var minY2 = axisY2.min,
                    maxY2 = axisY2.max;

                if (!maxY2 || maxY2 < 0) {
                    // fix display
                    maxY2 = -minY2;
                    chart.config.options.scales.yAxes[1].ticks.min =
                        chart.scales.y2.min;
                    chart.config.options.scales.yAxes[1].ticks.max = -chart
                        .config.options.scales.yAxes[1].ticks.min;
                }

                var delta = maxY2 - minY2,
                    coef = minY2 / delta,
                    maxY3 = axisY3.max,
                    minY3 = (maxY3 * minY2) / delta / (1 + coef);

                chart.config.options.scales.yAxes[2].ticks.min = minY3;
                chart.config.options.scales.yAxes[2].ticks.max = maxY3;

                chart.update();
            }
        }

        /**
         * Generate and display dataInfogreffe's data in table HTML
         * @param tableSelector
         * @param data
         */
        function setArrayDisplay(tableSelector, data) {
            var $table = $(tableSelector),
                datasets = data.data,
                content = '<thead><tr><th></th>';

            // header
            if (datasets && Array.isArray(datasets)) {
                datasets.forEach((yearObj) => {
                    content +=
                        '<th class="header-text" colspan="2">' +
                        yearObj.year +
                        '</th>';
                });
            }

            content += '</tr></thead><tbody>';

            // body - Chiffre d'affaires
            if (data.isCA) {
                content +=
                    '<tr><td class="year header-text">Chiffre(s) d\'affaires</td>';
                datasets.forEach((yearObj) => {
                    content +=
                        '<td class="help-pointer" style="text-align:right; padding-right:5px" title="';
                    content += yearObj.caFormatted ? yearObj.caFormatted : '';
                    content += '">';
                    if (yearObj.caFormattedReduced) {
                        var arr = separateFormattedReducedNumber(
                            yearObj.caFormattedReduced
                        );
                        content += arr[0] + '</td><td>' + arr[1];
                    } else {
                        content += '</td><td>';
                    }
                    content += '</td>';
                });
                content += '</tr>';
            }

            // body - Résultats
            if (data.isResults) {
                content += '<tr><td class="year header-text">Résultat(s)</td>';
                datasets.forEach((yearObj) => {
                    content +=
                        '<td class="help-pointer" style="text-align:right; padding-right:5px" title="';
                    content += yearObj.resultsFormatted
                        ? yearObj.resultsFormatted
                        : '';
                    content += '">';
                    if (yearObj.resultsFormattedReduced) {
                        var arr = separateFormattedReducedNumber(
                            yearObj.resultsFormattedReduced
                        );
                        content += arr[0] + '</td><td>' + arr[1];
                    } else {
                        content += '</td><td>';
                    }
                    content += '</td>';
                });
                content += '</tr>';
            }

            // body - ratio CA/Résultats (Marges)
            /* if (data.isCA && data.isResults) {
				content += '<tr><td class="header-text">Marge(s)</td>';
				datasets.forEach(function (yearObj) {
					content += '<td class="help-pointer" style="text-align:right; padding-right:5px" title="';
					content += yearObj.ratioCaResultsFormatted ? yearObj.ratioCaResultsFormatted : '';
					content += '">';
					if (yearObj.ratioCaResults) {
						content += yearObj.ratioCaResults + '</td><td>%</td>';
					} else {
						content += '</td><td></td>';
					}
				});
				content += '</tr>';
			} */

            // body - Effectif
            if (data.isEffectif) {
                content += '<tr><td class="header-text">Effectif(s)</td>';
                datasets.forEach((yearObj) => {
                    content +=
                        '<td class="help-pointer" style="text-align:right; padding-right:5px" title="';
                    content += yearObj.effectifFormatted
                        ? yearObj.effectifFormatted
                        : '';
                    content += '">';
                    if (yearObj.effectifFormatted) {
                        var arr = separateFormattedReducedNumber(
                            yearObj.effectifFormatted
                        );
                        content += arr[0] + '</td><td>' + arr[1];
                    } else {
                        content += '</td><td>';
                    }
                    content += '</td>';
                });
                content += '</tr>';
            }

            content += '</tbody>';

            $table.append(content);
        }

        /**
         * set interface behavior for DetailResultKeyFigures's section
         */
        function setDetailResultKeyFiguresManager(callback) {
            // Add listeners on tabs btn
            var $buttons = $('button.key-figures-tab'),
                $sectionDisplay = $('div.key-figures-item');

            $('section.detail-result-key-figures').on(
                'click',
                'button',
                (e) => {
                    e.stopImmediatePropagation();
                    $buttons.toggleClass('activ');
                    $sectionDisplay.toggleClass('visible');
                    if (chart) {
                        chart.destroy();
                    }
                    setChartDisplay(canvasIdSelector, dataSet);
                }
            );

            if (callback) {
                callback();
            }
        }

        /**
         * return HTML data to display for each provider
         * @param provider
         * @param siren
         * @param callback
         */
        function getWebServiceInfo(provider, siren, callback) {
            var urlWebservice = '/webservices';

            provider = provider || null;
            siren = siren || null;

            if (siren) {
                $.ajax({
                    method: 'GET',
                    url: urlWebservice,
                    dataType: 'json',
                    data: {
                        siren: siren,
                        webservice: provider,
                    },
                })
                    .done((data) => {
                        if (typeof data !== 'string') {
                            callback(data);
                        }
                    })
                    .fail(() => {
                        console.log('Request fails for', provider);
                        console.log(arguments);
                    })
                    .always(() => {});
            }
        }

        /**
         * extract number and units in an array
         * @param arg
         * @returns {Array}
         */
        function separateFormattedReducedNumber(arg) {
            if (!arg) {
                return;
            }
            arg += '';

            var arr = [];

            var matched = arg.match(/[0-9]\s[^0-9]/);

            if (matched.length) {
                var sep = matched[0].charAt(2),
                    idx = arg.indexOf(sep);

                arr[0] = arg.substring(0, idx);
                arr[1] = arg.substring(idx, arg.length);
            } else {
                arr = [arg, ''];
            }

            return arr;
        }
    });
})(jQuery, document, window);
